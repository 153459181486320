import React, { useMemo } from "react";
import "components/auth/onboarding.css";
import SectionTitle from "../SectionTitle";
import LoginInput from "components/auth/common/LoginInput";
import { iContactInfoForm, iFromErrors } from "components/auth/common/types";
import moment from "moment";
import { SectionOptions } from "components/rep/accounts/cardNewDesign/types";

interface ContactInformationProps {
  handleChange: (prop: string) => (value: string) => void;
  data: iContactInfoForm;
  formErrors: iFromErrors;
}

const cssPrefix = "onboarding-";
const ContactInformation: React.FC<ContactInformationProps> = ({
  data,
  handleChange,
  formErrors,
}) => {
  const formatPhoneNumber = (_prop: string) => (value: string) => {
    const phoneNumber = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
    handleChange("phone")(formattedPhoneNumber);
  };

  const timezoneOptions: SectionOptions[] = useMemo(() => {
    return moment.tz
      .names()
      .filter((name) => name.includes("US/"))
      .map((tz) => {
        return {
          label: tz,
          value: tz,
        };
      });
  }, []);
  return (
    <div className={cssPrefix + "section-container"}>
      <SectionTitle label="Contact Information" />
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="Company Name"
          value={data?.name}
          name={"name"}
          onChange={handleChange}
          type="name"
          placeholder="Enter Company Name"
          helperText={formErrors.name}
        />
        <LoginInput
          label="Company Email"
          value={data?.email}
          name={"email"}
          onChange={handleChange}
          type="email"
          placeholder="Enter Company Email"
          helperText={formErrors.email}
        />
      </div>
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="Company Phone"
          value={data?.phone}
          name={"phone"}
          onChange={formatPhoneNumber}
          type="text"
          placeholder="Enter Company Phone Number"
        />
        <LoginInput
          label="Company Website URL"
          value={data?.url}
          name={"url"}
          onChange={handleChange}
          type="text"
          placeholder="Enter Company Website link"
          helperText={formErrors.url}
        />
      </div>
      <LoginInput
        label="Timezone"
        value={data?.timezone}
        name={"timezone"}
        onChange={handleChange}
        type="select"
        placeholder="Select Timezone"
        options={timezoneOptions}
        helperText={formErrors.timezone}
      />
    </div>
  );
};

export default ContactInformation;
