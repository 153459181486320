import React, { ReactNode, useState } from "react";

interface CustomTooltipProps {
  tooltip?: string;
  children: ReactNode;
  tooltipClass?: string;
  customContainerStyle?: JObj;
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  tooltipClass,
  tooltip,
  children,
  customContainerStyle,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div
      style={
        customContainerStyle || {
          position: "relative",
          display: "flex",
          flex: 1,
        }
      }
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <div className={tooltipClass}>{tooltip}</div>}
    </div>
  );
};

export default CustomTooltip;
