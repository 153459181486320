import React from "react";
import "components/auth/onboarding.css";
import SectionTitle from "../SectionTitle";
import LoginInput from "components/auth/common/LoginInput";
import { iFoodInfoForm } from "components/auth/common/types";

interface FoodPreferencesProps {
  handleChange: (prop: string) => (value: string) => void;
  data: iFoodInfoForm;
}

const cssPrefix = "onboarding-";
const FoodPreferences: React.FC<FoodPreferencesProps> = ({
  data,
  handleChange,
}) => {
  return (
    <div className={cssPrefix + "section-container"}>
      <SectionTitle label="Food Preferences" />
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="Total # of Orders"
          value={data?.amount}
          name={"amount"}
          onChange={handleChange}
          type="number"
          placeholder="Total # of Orders"
        />
        <LoginInput
          label="# of Vegetarian Orders"
          value={data?.vegetarian}
          name={"vegetarian"}
          onChange={handleChange}
          type="number"
          placeholder="# of Vegetarian Orders"
        />
      </div>
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="# of Vegan Orders"
          value={data?.vegan}
          name={"vegan"}
          onChange={handleChange}
          type="number"
          placeholder="# of Vegan Orders"
        />
        <LoginInput
          label="# of Lactose Allergy Orders"
          value={data?.lactose}
          name={"lactose"}
          onChange={handleChange}
          type="number"
          placeholder="# of Lactose Allergy Orders"
        />
      </div>
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="# of Nut Allergy Orders"
          value={data?.nuts}
          name={"nuts"}
          onChange={handleChange}
          type="number"
          placeholder="# of Nut Allergy Orders"
        />
        <LoginInput
          label="Food Instructions"
          value={data?.instructions}
          name={"instructions"}
          onChange={handleChange}
          type="text"
          placeholder="Provide any food instructions"
        />
      </div>
    </div>
  );
};

export default FoodPreferences;
