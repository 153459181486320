import React, { useEffect, useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import RegisterPremierUser from "./register/RegisterPremierUser.jsx";
import { UserContext } from "./context/UserContext";
import { roleBasedRedirect } from "./common/utilities";
import Register from "./Register.tsx";
import "../css/join.css";
import "../css/animate-3.7.0.css";
import "../css/font-awesome-4.7.0.min.css";
import "../css/bootstrap-4.1.3.min.css";
import "../css/owl-carousel.min.css";
import "../css/jquery.datetimepicker.min.css";
import "../css/linearicons.css";
import "../css/style.css";

const Join = ({ match }) => {
  const history = useHistory();
  const { state } = useContext(UserContext);

  useEffect(() => {
    if (state.currentUser && state.currentUser.roleName) {
      roleBasedRedirect(state.currentUser.roleName, history);
    }
  }, []);

  return (
    <React.Fragment>
      <Route path={`${match.url}`} component={Register} />
      <Route path={`${match.url}/rep`} component={RegisterPremierUser} />
      {/* <Route path={`${match.url}/checkout`} component={Checkout} /> */}
    </React.Fragment>
  );
};

export default Join;
