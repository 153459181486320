import React, { useEffect, useState } from "react";
import * as userServices from "../../services/userServices.js";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useJwt } from "react-jwt";
import { useParams, useHistory } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import NavbarHeader from "./components/NavbarHeader.js";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "components/common/LoadingSpinner.jsx";
import { PRIVACY_LINK, TERMS_LINK } from "components/common/constants/links.js";
import { makeToast } from "helpers/common";

const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const RegisterInvited = () => {
  const history = useHistory();
  const { token } = useParams();
  const { decodedToken } = useJwt(token);

  const [email, setEmail] = useState();
  const [client, setClient] = useState();

  const [viewTerms, setViewTerms] = useState(false);
  const [viewPrivacy, setViewPrivacy] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibility, togglePasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, toggleConfirmPasswordVisibility] =
    useState(false);

  useEffect(() => {
    if (decodedToken && decodedToken.email) {
      setName(decodedToken.name);
      setEmail(decodedToken.email);
      setClient(decodedToken.client);
    }
  }, [decodedToken]);

  const handleSubmit = () => {
    if (!regex.test(password)) {
      return toast.warning(
        "Password must be at least 6 characters long and must contain at least 1 uppercase letter, 1 lowercase letter and 1 number."
      );
    }
    if (password !== confirmPassword) {
      return toast.error("Passwords dont match");
    }
    if (!agreeTerms || !agreePrivacy) {
      return;
    }

    const payload = {
      Email: email,
      Password: password,
      ConfirmPassword: confirmPassword,
    };
    userServices
      .registerRep(payload)
      .then(() => {
        swal("Success", "You can now login", "success");
        setTimeout(() => {
          history.replace("/");
        }, 3000);
      })
      .catch((e) => {
        toast.error(e.message.toString());
      });
  };

  if (!decodedToken || !token) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <LoadingSpinner variant="alt" />
      </div>
    );
  }

  return (
    <>
      <NavbarHeader />
      <Container>
        <div className="d-flex justify-content-center">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p style={{ fontSize: 16 }} className="mt-4">
              <span className="fw-bold text-black"> {client} </span>
              has invited you to join the
              <span className="text-black"> MyRep </span>platform.
            </p>
            <div className="mb-2">
              <label>Name</label>
              <Form.Control
                style={{ marginTop: -10 }}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Email address</label>
              <Form.Control
                style={{ marginTop: -10 }}
                type="email"
                placeholder={email}
                disabled
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </div>

            <div style={{ position: "relative" }} className="mb-2">
              <label>Password</label>
              <Form.Control
                style={{ marginTop: -10 }}
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src="/eye-icon.svg"
                alt="toggle-password-visibility"
                onClick={() => {
                  togglePasswordVisibility((prev) => !prev);
                }}
                style={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                  width: 20,
                  height: "100%",
                  zIndex: 1,
                }}
              />
            </div>
            <div style={{ position: "relative" }} className="mb-2">
              <label>Confirm Password</label>
              <Form.Control
                style={{ marginTop: -10 }}
                type={confirmPasswordVisibility ? "text" : "password"}
                placeholder="Confirm Password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <img
                src="/eye-icon.svg"
                onClick={() => {
                  toggleConfirmPasswordVisibility((prev) => !prev);
                }}
                alt="toggle-confirm-password-visibility"
                style={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                  width: 20,
                  height: "100%",
                  zIndex: 1,
                }}
              />
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <span className="d-flex">
                <Form.Check
                  onChange={() => {
                    setAgreeTerms((prev) => !prev);
                  }}
                  required
                  type="checkbox"
                  label=""
                />
                <p
                  onClick={() => setViewTerms(true)}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-decoration-underline"
                >
                  I Accept Terms of Service
                </p>
              </span>
              <span className="d-flex">
                <Form.Check
                  onChange={() => {
                    setAgreePrivacy((prev) => !prev);
                  }}
                  required
                  type="checkbox"
                  label=""
                />
                <p
                  onClick={() => setViewPrivacy(true)}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-decoration-underline"
                >
                  I Agree to Privacy Policy
                </p>
              </span>
            </div>

            <div className="d-flex justify-content-center px-5">
              <button
                type="submit"
                onClick={handleSubmit}
                className="Button w-75"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>

        {/* Terms of Service Modal*/}
        <Modal
          show={viewTerms}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setViewTerms(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <embed
              src={TERMS_LINK}
              style={{
                width: 700,
                height: 700,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="Button" onHide={() => setViewTerms(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Privacy Modal */}
        <Modal
          show={viewPrivacy}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setViewPrivacy(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <embed src={PRIVACY_LINK} width="700px" height="700px" />
          </Modal.Body>
          <Modal.Footer>
            <button className="Button" onHide={() => setViewPrivacy(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default RegisterInvited;
