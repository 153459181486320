import swal from "sweetalert";
import { bookAppt, confirmation } from "../../services/calendarService.js";
import { confirmFood } from "../../services/yelpService";
import * as helperService from "../../services/helperService";
import {
  Messages,
  RecurrenceFrequency,
} from "./constants/appointment.constants.js";
import Holidays from "date-holidays";
import moment from "moment";
import { DATE_FORMAT, ROLE_NAMES, TIME_FORMAT } from "helpers/common.ts";

export const callBookAppt = (appt, rep, calendarEvents) => {
  // convert appt fields back into db fields? I think...
  const apptChanges = { id: appt.id, booked: true, bookedByRep: rep };
  return bookAppt(apptChanges)
    .then((response) => {
      if (!response.isSuccessful) {
        helperService.showSWALPopupForAppointmentEvents(
          false,
          Messages.APPOINTMENT_BOOKED
        );
        return;
      }
      helperService.showSWALPopupForAppointmentEvents(
        true,
        Messages.APPOINTMENT_BOOKED
      );

      const newCalendarEvents = [...calendarEvents];
      const indexToUpdate = newCalendarEvents.findIndex(
        (na) => na.id === appt.id
      );
      if (indexToUpdate > -1) {
        newCalendarEvents[indexToUpdate] = {
          ...newCalendarEvents[indexToUpdate],
          ...apptChanges,
        };
        return newCalendarEvents;
      }
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data.errors.includes("You have reached your limit")
      ) {
        swal({
          icon: "warning",
          title:
            "This rep can't book more appointments because doing so would exceed their limit",
        });
      }
      console.error(err);
      throw err;
    });
};

export const guessFileNameFromUrl = (fileUrl) => {
  if (!fileUrl) return "";
  const urlSegments = fileUrl.split("-");
  return urlSegments[urlSegments.length - 1];
};

export const isUserRep = (u) => [3, 5].includes(u.roleId);

export const callChooseFood = (appt, restaurants) => {
  if (!restaurants || restaurants.length === 0) {
    return { success: false, error: "No resturants selected" };
  }
  const payload = {
    apptId: appt.id,
    businesses: restaurants.map((r) => ({ apptId: appt.id, ...r })),
  };
  return confirmFood(payload, appt.id).then((res) => {
    if (res.success && !!appt.bookedByRep) {
      sendFoodEmail(appt, restaurants);
    }
    return res;
  });
};

export const sendFoodEmail = (appt, restaurants) => {
  if (restaurants.length > 0) {
    const restaurantsData = restaurants.map((r) => ({
      Address: r.location.display_address?.join(", "),
      Name: r.name,
    }));

    let payload = {
      restaurants: restaurantsData,
      apptId: appt.id,
    };

    // This sends an email
    confirmation(payload).catch(console.error);
  }
};

export const dedupeById =
  (lookupDict = {}) =>
  (record) => {
    if (lookupDict[record.id]) return false;
    lookupDict[record.id] = true;
    return true;
  };

export const roleBasedRedirect = (role, navigator) => {
  if (role === "Client") {
    navigator.push("/client/calendar");
  } else if (
    [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER].includes(role)
  ) {
    navigator.push("/rep/appointments");
  } else if (role === "Admin") {
    navigator.push("/admin/approvals");
  } else if (role === "Delegated User") {
    navigator.push("/impersonation-options");
  }
};

export const generateDateListOfRecurrenceSeries = (
  frequency,
  startDateStr,
  endDateStr,
  excludeDates = [],
  limitTo = null
) => {
  let limitedDate = null;
  if (limitTo) {
    limitedDate = moment().add(limitTo.value, limitTo.unit);
  }
  const startDate = new Date(startDateStr);
  let endDate = new Date(
    // fallback for infinite repeat
    endDateStr || moment().add(limitTo.value, limitTo.unit).format("YYYY-MM-DD")
  );

  if (limitTo && moment(endDate).isAfter(limitedDate)) {
    endDate = limitedDate.format("YYYY-MM-DD");
  }

  const dateList = [];

  if (frequency === RecurrenceFrequency.EVERY_WEEKDAY) {
    let currentDate = startDate;
    while (currentDate <= endDate) {
      if (
        currentDate.getDay() >= 1 &&
        currentDate.getDay() <= 5 &&
        !excludeDates.includes(currentDate.toISOString().slice(0, 10))
      ) {
        dateList.push(currentDate.toISOString().slice(0, 10));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else if (frequency === RecurrenceFrequency.EVERY_MONTH_SAME_DAY) {
    const targetDayOfMonth = startDate.getDate();
    let currentDate = startDate;
    while (currentDate <= endDate) {
      if (
        currentDate.getDate() === targetDayOfMonth &&
        !excludeDates.includes(currentDate.toISOString().slice(0, 10))
      ) {
        dateList.push(currentDate.toISOString().slice(0, 10));
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  } else if (frequency === RecurrenceFrequency.EVERY_WEEK_SAME_DAY) {
    const targetDayOfWeek = startDate.getDay();
    let currentDate = startDate;
    while (currentDate <= endDate) {
      if (
        currentDate.getDay() === targetDayOfWeek &&
        !excludeDates.includes(currentDate.toISOString().slice(0, 10))
      ) {
        dateList.push(currentDate.toISOString().slice(0, 10));
      }
      currentDate.setDate(currentDate.getDate() + 7);
    }
  }

  return dateList;
};

export const getRepProductsList = (rep) => {
  return Object.keys(rep)
    .filter((el) => el.startsWith("product"))
    .map((el) => {
      if (rep[el]) return rep[el];
      return null;
    })
    .filter((el) => el);
};

export const repSearchFilter = (
  searchQuery,
  allReps,
  filters = {
    approvedOnly: false,
    nonBlockedOnly: false,
    pendingReviewOnly: false,
    bypassedOnly: false,
  }
) => {
  const value = searchQuery?.toLowerCase();

  let reps = allReps;
  if (filters.nonBlockedOnly) {
    reps = reps.filter((rep) => {
      if (!rep.isIndefiniteBlock && !rep.blockEndDate) return true;
      if (rep.blockEndDate && moment().isAfter(moment(rep.blockEndDate)))
        return true;

      return false;
    });
  }
  if (filters.approvedOnly) {
    reps = reps.filter((rep) => rep.approved);
  }
  if (filters.bypassedOnly) {
    reps = reps.filter((rep) => rep.restrictionsBypassed);
  }
  if (filters.pendingReviewOnly) {
    reps = reps.filter((rep) => {
      return rep.approved === null;
    });
  }

  if (!value) return reps;
  else {
    if (value.includes("@")) {
      return reps.filter((rep) => rep.email.startsWith(value));
    }

    const res = reps.filter((rep) => {
      const searchWords = value.toLowerCase().split(" ");
      const products = getRepProductsList(rep).map((el) => el.toLowerCase());
      const combinedFields = [rep.firstName, rep.lastName, rep.name, rep.email]
        .filter(Boolean)
        .join(" ")
        .toLowerCase();
      return searchWords.every(
        (word) =>
          combinedFields.includes(word) ||
          products.some((el) => el.includes(word))
      );
    });

    return res;
  }
};

export function deepCopy(input) {
  if (Array.isArray(input)) {
    return input.map(deepCopy);
  } else if (typeof input === "object" && input !== null) {
    const copy = {};
    for (let key in input) {
      if (input.hasOwnProperty(key)) {
        copy[key] = deepCopy(input[key]);
      }
    }
    return copy;
  } else {
    return input;
  }
}

function getPlainText(html) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  return tempElement.textContent
    .replace(/\s+/g, " ")
    .trim()
    .replaceAll(" ", "");
}
export function objectsHaveSameValues(obj1, obj2, sameLength = false) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (sameLength && keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = key === "policies" ? getPlainText(obj1[key]) : obj1[key];
    const val2 = key === "policies" ? getPlainText(obj2[key]) : obj2[key];

    if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

export const getTimesList = () => {
  const timeIncrements = [{ value: 0, display: "" }];
  const dummyDate = moment("1970-01-01 00:00");
  for (let i = 60 * 7; i <= 60 * 22; i += 15) {
    timeIncrements.push({
      value: i,
      display: dummyDate.clone().add(i, "minutes").format(TIME_FORMAT),
    });
  }

  return timeIncrements;
};

export const getNextReleaseDate = (
  options = { formatWithSuffix: false },
  releaseDateOverrides = []
) => {
  const now = moment();

  const getSuffix = (date) => {
    const d = date.date();
    if (d === 1) return "st";
    else if (d === 2) return "nd";
    else if (d === 3) return "rd";
    else return "th";
  };

  const getValidReleaseDate = (date) => {
    const hd = new Holidays("US");
    const holidays = [
      ...hd.getHolidays(now.clone().format("YYYY")),
      ...hd.getHolidays(now.clone().add(1, "year").format("YYYY")),
      { date: "2025-01-02" },
      { date: "2025-01-03" },
    ];

    const isHoliday = holidays.find((el) =>
      date.clone().isSame(moment(el.date), "D")
    );
    if (isHoliday) return getValidReleaseDate(date.clone().add(1, "day"));

    const isSunday = date.day() === 0;
    if (isSunday) return getValidReleaseDate(date.clone().add(1, "day"));

    const isSaturday = date.day() === 6;
    if (isSaturday) return getValidReleaseDate(date.clone().add(2, "days"));

    return date;
  };

  const result = (displayDate) => {
    return options.formatWithSuffix
      ? displayDate.format("dddd, MMMM D") + getSuffix(displayDate)
      : displayDate.format(DATE_FORMAT);
  };

  const currentMonth = now.clone().month();

  // if this is a release month, calculate this month's release date
  if (currentMonth % 2 === 0) {
    const thisMonthsReleaseDate = getValidReleaseDate(
      now.clone().startOf("month")
    );
    // if current date / today is before this month's release date, return release date
    if (now.clone().date() < thisMonthsReleaseDate.date())
      return result(thisMonthsReleaseDate);
    if (now.clone().date() === thisMonthsReleaseDate.date()) {
      //if today is release date, get current time in utc and get release time 12pm utc
      const utcDate = now.clone().utc();
      const utcTargetTime = moment()
        .utc()
        .set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
      // get hours offset and add that to local current time to get local release time
      const timeDiffHours = utcTargetTime.diff(utcDate, "hours");
      const releaseTimeinLocalTz = now.clone().add(timeDiffHours, "hours");
      // if local current time is before local release time, show this month's release date
      if (now.isBefore(releaseTimeinLocalTz))
        return result(thisMonthsReleaseDate);
    }
  }

  const startOfNextReleaseMonth =
    currentMonth % 2 === 0
      ? now.clone().add(2, "months").startOf("month")
      : now.clone().add(1, "month").startOf("month");

  let date = startOfNextReleaseMonth.clone();
  const hasOverride = releaseDateOverrides.find(
    (e) => e.month === startOfNextReleaseMonth.clone().month() + 1
  );
  if (hasOverride) {
    const { year, month, date: day } = hasOverride;
    const releaseDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
    date = moment(releaseDate);
  } else date = getValidReleaseDate(startOfNextReleaseMonth);

  return result(date);
};

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/;
  return emailRegex.test(email);
}

export const extractAppointmentRequirementsFromProfileData = (data) => {
  const {
    isPreApprovalRequired,
    appointmentBookingIntervalType,
    numMonthsBetweenAppointments,
    numWeeksBetweenAppointments,
  } = data;
  return {
    isPreApprovalRequired,
    appointmentBookingIntervalType,
    numWeeksBetweenAppointments,
    numMonthsBetweenAppointments,
  };
};

export const extractTextFromHTML = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  const textContent = tempElement.textContent;
  tempElement.remove();
  return textContent;
};
