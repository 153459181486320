import moment from "moment";

export const CLIENT_NAVIGATION = [
  "Personal Information",
  "Contact Information",
  "Your Location",
  "Food Preferences",
  "Office Policies",
];
export const REP_NAVIGATION = [
  "Personal Information",
  "Contact Information",
  "Your Location",
  "Products List",
];
export const ClientFormRules = {
  firstName: { required: true },
  lastName: { required: true },
  email: { required: true, email: true },
  phone: { required: true },
  url: { required: true, url: true },
  lineOne: { required: true },
  city: { required: true },
  zip: { required: true, zipCode: true },
  // fileUrl: { required: true },
  name: { required: true },
  timezone: { required: true },
};
export const timezoneOptions = moment.tz
  .names()
  .filter((name: string) => name.includes("US/"))
  .map((tz: string) => {
    return {
      label: tz,
      value: tz,
    };
  });
