import React, { useMemo } from "react";
import "components/auth/onboarding.css";
import { CLIENT_NAVIGATION, REP_NAVIGATION } from "../common/constants";

interface NavigationSectionProps {
  isRep?: boolean;
  selectedSection: string;
  handleScroll: (value: string) => void;
}

const cssPrefix = "onboarding-";
const NavigationSection: React.FC<NavigationSectionProps> = ({
  isRep,
  selectedSection,
  handleScroll,
}) => {
  const navigationLabels = useMemo(() => {
    return isRep ? REP_NAVIGATION : CLIENT_NAVIGATION;
  }, [isRep]);

  return (
    <div className={cssPrefix + "content-navigation"}>
      {navigationLabels?.map((label: string, index: number) => (
        <p
          className={cssPrefix + "navigation-label"}
          key={index}
          style={{
            color: label === selectedSection ? "#000" : "#808080",
          }}
          onClick={() => handleScroll(label)}
        >
          {label}
        </p>
      ))}
    </div>
  );
};

export default NavigationSection;
