import React, { useRef } from "react";
import "components/auth/onboarding.css";
import "components/auth/common/common.css";
import SectionTitle from "../SectionTitle";
import { iFromErrors, iProfileInfoForm } from "components/auth/common/types";
import LoginInput from "components/auth/common/LoginInput";
import defaultAvatar from "assets/images/auth/defaultAvatar.png";
import { FiUploadCloud } from "react-icons/fi";
import { makeToast } from "helpers/common";
import * as filesService from "../../../../services/filesService";

interface ProfileInformationProps {
  handleChange: (prop: string) => (value: string) => void;
  data: iProfileInfoForm;
  formErrors: iFromErrors;
}

const cssPrefix = "onboarding-";
const ProfileInformation: React.FC<ProfileInformationProps> = ({
  data,
  handleChange,
  formErrors,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      if (fileList[0].size > 2000000) {
        alert("Sorry, this file's size exceeds the 2MB limit");
      } else {
        const formData = new FormData();

        try {
          Array.from(fileList).forEach((file) => {
            formData.append("files", file);
          });

          makeToast("Uploading", "info", 1000);
          const res = await filesService.uploadFile(formData);
          makeToast("Upload Success", "success", 1000);
          if (res?.length > 0) handleChange("fileUrl")(res[0]);
        } catch (error) {
          console.error({ error });
        }
      }
    }
  };

  return (
    <div className={cssPrefix + "section-container"}>
      <SectionTitle label="Personal Information" />
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="Office Contact First Name"
          value={data?.firstName}
          name={"firstName"}
          onChange={handleChange}
          type="firstName"
          placeholder="Enter First Name"
          helperText={formErrors.firstName}
        />
        <LoginInput
          label="Office Contact Last Name"
          value={data?.lastName}
          name={"lastName"}
          onChange={handleChange}
          type="lastName"
          placeholder="Enter Last Name"
          helperText={formErrors.lastName}
        />
      </div>
      <div className={cssPrefix + "profile-pic-container"}>
        <img
          src={data.fileUrl || defaultAvatar}
          className={cssPrefix + "profile-pic"}
        />
        <button
          className={cssPrefix + "upload-btn"}
          onClick={handleFileUploadClick}
        >
          <FiUploadCloud />
          Upload Image
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
        <p className={cssPrefix + "specification-warning"}>
          (Images can be up to <strong>2MB</strong> in size and should be in
          portrait rather than landscape orientation.)
        </p>
        {formErrors.fileUrl && (
          <p className="login-from-helper-text">{formErrors.fileUrl}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileInformation;
