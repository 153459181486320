import {
  iLoginForm,
  iRecoverForm,
  iRegisterClientForm,
  iResetForm,
} from "./types";

export const initialLoginForm: iLoginForm = {
  email: "",
  password: "",
  emailValidation: null,
  passwordValidation: null,
};
export const initialRegisterClientForm: iRegisterClientForm = {
  email: "",
  password: "",
  confirmPassword: "",
  acceptedTerms: false,
  acceptedPolicy: false,
};
export const initialRecoverForm: iRecoverForm = {
  email: "",
  emailValidation: null,
};
export const initialResetForm: iResetForm = {
  password: "",
  passwordConfirm: "",
};

export const loginFormValidationRules = {
  email: { required: true, email: true },
  password: { required: true },
};
export const registerClientFormValidationRules = {
  email: { required: true, email: true },
  password: { required: true },
};
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
