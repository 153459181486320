import React, { useRef, useState } from "react";
import "components/auth/onboarding.css";
import NavigationSection from "./NavigationSection";
import {
  CLIENT_NAVIGATION,
  ClientFormRules,
  REP_NAVIGATION,
} from "../common/constants";
import ProfileInformation from "./forms/ProfileInformation";
import {
  iContactInfoForm,
  iFoodInfoForm,
  iFromErrors,
  iLocationInfoForm,
  iProfileInfoForm,
} from "../common/types";
import ContactInformation from "./forms/ContactInformation";
import LocationInformation from "./forms/Location";
import FoodPreferences from "./forms/FoodPreferences";
import SectionTitle from "./SectionTitle";
import LoginInput from "../common/LoginInput";
import { makeToast } from "helpers/common";
import { validate } from "helpers/validation.helper";
import * as profileServices from "../../../services/profileServices";
import { useHistory } from "react-router-dom";
interface OnboardingFormProps {
  isRep?: boolean;
  onSubmit?: () => Promise<void>;
}

const cssPrefix = "onboarding-";
const OnboardingForm: React.FC<OnboardingFormProps> = ({ isRep, onSubmit }) => {
  const history = useHistory();

  const [selectedSection, setSelectedSection] = useState<string>(
    isRep ? REP_NAVIGATION[0] : CLIENT_NAVIGATION[0]
  );

  const sectionRefs = {
    ProfileInformation: useRef<HTMLDivElement>(null),
    ContactInformation: useRef<HTMLDivElement>(null),
    LocationInformation: useRef<HTMLDivElement>(null),
    FoodPreferences: useRef<HTMLDivElement>(null),
    OtherPolicies: useRef<HTMLDivElement>(null),
  };

  const [profileData, setProfileData] = useState<iProfileInfoForm>({});
  const [contactData, setContactData] = useState<iContactInfoForm>({});
  const [locationData, setLocationData] = useState<iLocationInfoForm>({});
  const [foodData, setFoodData] = useState<iFoodInfoForm>({});
  const [formErrors, setFormErrors] = useState<iFromErrors>({});

  const handleProfileChange = (prop: string) => (value: string) => {
    setProfileData((prev: iProfileInfoForm) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const handleContactChange = (prop: string) => (value: string) => {
    setContactData((prev: iContactInfoForm) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const handleLocationChange = (prop: string) => (value: string) => {
    setLocationData((prev: iLocationInfoForm) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const handleFoodChange = (prop: string) => (value: string) => {
    setFoodData((prev: iFoodInfoForm) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const handleScroll = (value: string) => {
    setSelectedSection(value);
    switch (value) {
      case CLIENT_NAVIGATION[0]:
        if (sectionRefs.ProfileInformation.current) {
          sectionRefs.ProfileInformation.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        break;
      case CLIENT_NAVIGATION[1]:
        if (sectionRefs.ContactInformation.current) {
          sectionRefs.ContactInformation.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        break;
      case CLIENT_NAVIGATION[2]:
        if (sectionRefs.LocationInformation.current) {
          sectionRefs.LocationInformation.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        break;
      case CLIENT_NAVIGATION[3]:
        if (sectionRefs.FoodPreferences.current) {
          sectionRefs.FoodPreferences.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        break;
      case CLIENT_NAVIGATION[4]:
        if (sectionRefs.OtherPolicies.current) {
          sectionRefs.OtherPolicies.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        break;
      default:
        break;
    }
  };
  const validateFormData = (formData: LooseObject, rules: LooseObject) => {
    const errors: { [key: string]: string | null } = {};

    Object.keys(rules).forEach((key) => {
      const validationError = validate(formData?.[key], rules?.[key], true);
      if (Array.isArray(validationError) && validationError?.length > 0) {
        errors[key] = validationError[validationError?.length - 1];
      } else {
        errors[key] = null;
      }
    });
    setFormErrors(errors);
    return Object.values(errors).some((error) => error !== null) ? true : false;
  };
  const handleSave = async () => {
    try {
      const dataToSave = {
        user: profileData,
        company: contactData,
        location: locationData,
        food: foodData,
      };

      await profileServices.createProfile(dataToSave);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err);
        makeToast(`Something went wrong. ${err.message}`, "error");
      } else {
        // Handle unexpected error types
        console.error("An unknown error occurred");
        makeToast("Something went wrong. Please try again.", "error");
      }
    }
    makeToast(
      "Thanks for that information. You can make changes to your profile at any time you want to by visiting your Profile page",
      "success"
    );

    const redirectTo = isRep ? "/rep/accounts" : "/client/calendar";

    if (typeof onSubmit === "function") {
      onSubmit();
    }

    history.push(redirectTo);
  };
  const handleSubmit = async () => {
    const allData = {
      ...profileData,
      ...contactData,
      ...locationData,
      ...foodData,
    };
    const errors = validateFormData(allData, ClientFormRules);
    if (errors) makeToast("Please provide the required fields", "error");
    else await handleSave();
  };
  return (
    <div className={`${cssPrefix}container`}>
      <img
        className="Logo"
        style={{ height: 138, width: 146, margin: 0, objectFit: "contain" }}
        alt="MyRep"
        src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/271f67d25e15bf1f1ba0e3ce5662b552-newlogo.png"
      />
      <p className={`${cssPrefix}title`}>Welcome</p>
      <p className={`${cssPrefix}subtitle`}>
        We need a bit more information to get you started
      </p>
      <div className={cssPrefix + "content-container"}>
        <NavigationSection
          isRep={isRep}
          selectedSection={selectedSection}
          handleScroll={handleScroll}
        />
        <div className={cssPrefix + "content-forms custom-scroll-hidden "}>
          <div ref={sectionRefs.ProfileInformation}>
            <ProfileInformation
              data={profileData}
              handleChange={handleProfileChange}
              formErrors={formErrors}
            />
          </div>
          <div ref={sectionRefs.ContactInformation}>
            <ContactInformation
              data={contactData}
              handleChange={handleContactChange}
              formErrors={formErrors}
            />
          </div>
          <div ref={sectionRefs.LocationInformation}>
            <LocationInformation
              data={locationData}
              handleChange={handleLocationChange}
              formErrors={formErrors}
            />
          </div>
          <div ref={sectionRefs.FoodPreferences}>
            <FoodPreferences data={foodData} handleChange={handleFoodChange} />
          </div>
          <div ref={sectionRefs.OtherPolicies}>
            <SectionTitle label="Other Policies" />
            <LoginInput
              label="Office Policy"
              value={contactData?.policies}
              name={"policies"}
              onChange={handleContactChange}
              type="area"
              placeholder="What else would be useful for reps to know?"
            />
          </div>
          <button className={cssPrefix + "save-btn"} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingForm;
