import React from "react";
import "./policy.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

interface CancellationPolicyProps {}
const CancellationPolicy: React.FC<CancellationPolicyProps> = () => {
  const pdfUrl = "./cancellation-policy.pdf";
  return (
    <div className="policy-container">
      <a href={pdfUrl} download className="cancellation-download-button">
        Download Policy
      </a>
      <div className="pdf-viewer">
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      </div>
    </div>
  );
};

export default CancellationPolicy;
