import axios from "axios";
import { v2Request } from "../helpers/request";
import * as helper from "./serviceHelper";

const { REACT_APP_API_URL } = process.env;
const endpoint = REACT_APP_API_URL + "/user";

const create = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoint + "/create",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

const createProfile = (payload) => {
  return v2Request("/users/profile", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const edit = (payload) => {
  return v2Request("/users/profile", {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

const getAllProfiles = () => {
  return v2Request("/users/reps");
};
const getAllRepsNotFollowing = (query) => {
  return v2Request(`/users/reps-to-add${query}`);
};
const getAllAccounts = () => {
  return v2Request(`/offices`);
};

const getAllOfficesIFollow = (currentUserId) => {
  return v2Request(`/offices/${currentUserId}`);
};
const getOfficeRequirements = (officeId) => {
  return v2Request(`/offices/requirements/foreign/${officeId}`);
};
const getReps = () => {
  return v2Request("/users/reps");
};

const getProfile = () => {
  return v2Request("/users/profile");
};

export {
  create,
  createProfile,
  getProfile,
  getReps,
  getAllProfiles,
  edit,
  getAllAccounts,
  getAllOfficesIFollow,
  getOfficeRequirements,
  getAllRepsNotFollowing,
};
