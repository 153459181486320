import React from "react";
import "components/auth/ClientRegistration/clientRegistration.css";

interface AuthCheckboxInputProps {
  checked: boolean;
  handleCheckboxChange: () => void;
  handleSpamClick: () => void;
  label: string;
}
const AuthCheckboxInput: React.FC<AuthCheckboxInputProps> = ({
  checked,
  handleCheckboxChange,
  handleSpamClick,
  label,
}) => {
  const cssPrefix = "auth-page-";

  return (
    <div className={cssPrefix + "horizontal-container"}>
      <input
        type="checkbox"
        style={{ marginRight: 8, cursor: "pointer" }}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <span
        style={{ marginRight: 24, color: "#224894", cursor: "pointer" }}
        onClick={handleSpamClick}
      >
        {label}
      </span>
    </div>
  );
};

export default AuthCheckboxInput;
