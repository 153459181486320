import React, { useState } from "react";
import "./css/redesign-components.css";
import "./css/testimonial-section.css";
import quote from "assets/images/home/Quote.png";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import Logo from "assets/images/logo/circular_logo.png";

interface TestimonialsSectionsProps {}
interface testimonial {
  image: string;
  author: string;
  review: string;
}
const TESTIMONIALS = [
  {
    image: Logo,
    author: "Rhonda M, Oncology Practice Manager",
    review:
      "MyRep gives us back valuable time that we used to spend booking and rescheduling Rep appointments. This way we have more time for our patients. Those small segments of time make a big deal. Our office uses their tools to explain our schedule and guidelines so that our meetings are clear and fair for all parties concerned. I also like that we are notified if we have a Rep who tries to book outside our instructions. The MyRep team is professional, easy and fun to work with. We highly recommend them.",
  },
  {
    image: Logo,
    author: "MichaelM, Practice Management Institute",
    review:
      "MyRep, provides a centralized system where practice managers can efficiently handle appointments with pharmaceutical representatives, consolidating scheduling tasks into one platform and reducing the time and effort spent on coordinating meetings. This enables office staff to allocate more resources to patient care initiatives and essential administrative tasks. ",
  },
  // {
  //   image: office,
  //   author: "John T., Practice Operations Manager",
  //   review:
  //     "MyRep has been a game changer for our practice. We used to spend so much time coordinating and confirming appointments, but now it's all done seamlessly. The platform is easy to use, and their team is always available to assist with any issues we might have. I also appreciate the transparency it provides, ensuring that everyone follows our scheduling guidelines. It has allowed us to dedicate more time to patient care without the stress of appointment management. Highly recommended!",
  // },
];
const TestimonialsSections: React.FC<TestimonialsSectionsProps> = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState<number>(0);

  const handleNext = () => {
    setCurrentTestimonial((prevIndex) => (prevIndex + 1) % TESTIMONIALS.length);
  };

  const handlePrevious = () => {
    setCurrentTestimonial((prevIndex) =>
      prevIndex === 0 ? TESTIMONIALS.length - 1 : prevIndex - 1
    );
  };
  return (
    <div className="section-container">
      <div className="testimonial-container">
        <div className="testimonial-left">
          <Carousel
            activeIndex={currentTestimonial}
            controls={false}
            indicators={false}
          >
            {TESTIMONIALS.map((elem: testimonial, index: number) => (
              <Carousel.Item key={index}>
                <div className="review-container">
                  <div className="logo-container">
                    <img src={elem?.image} alt="" className="t-logo" />
                  </div>
                  <p className="review-text">{elem?.review}</p>
                  <p className="review-author">{elem?.author}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="testimonial-right">
          <div className="h-testimonial-top">
            <div className="testimonial-pill">Testimonials</div>
            <img src={quote} alt="" className="t-image-quote" />
          </div>
          <p className="t-title-text">
            Trusted and endorsed by industry leaders
          </p>
          <div className="t-btn-container">
            <button
              className="arrow-button"
              style={{
                background: "rgba(37, 170, 225, 0.2)",
              }}
              onClick={handlePrevious}
              disabled={currentTestimonial === 0}
            >
              <FaArrowLeft />
            </button>
            <button
              className="arrow-button"
              style={{
                backgroundColor: "#3F77CD",
              }}
              onClick={handleNext}
              disabled={currentTestimonial >= TESTIMONIALS.length - 1}
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSections;
