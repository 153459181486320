import React from "react";
import "components/auth/onboarding.css";

interface SectionTitleProps {
  label: string;
  overrideLabelClass?: string;
}
const cssPrefix = "onboarding-";

const SectionTitle: React.FC<SectionTitleProps> = ({
  label,
  overrideLabelClass,
}) => {
  return (
    <div className={cssPrefix + "title-container"}>
      <span className={`${cssPrefix}section-title ${overrideLabelClass || ""}`}>
        {label}
      </span>
      <div className={cssPrefix + "divider"} />
    </div>
  );
};

export default SectionTitle;
