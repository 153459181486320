import { Appointment } from "components/rep/accounts/cardNewDesign/types";
import moment from "moment";
import toast from "react-hot-toast";

const DATE_FORMAT: string = "M/D/YY";
const APPOINTMENT_DAY_FORMAT: string = "MMM Do, YYYY";
const TIME_FORMAT: string = "h:mma";
const WEEK_DAY: string = "dddd";

interface iNotificationType {
  label?: string;
  emailSubtext?: string;
  smsSubtext?: string;
  emailProp?: string;
  smsProp?: string;
  allowedRoles?: string[];
}
const ROLE_NAMES = {
  BASIC: "Basic",
  PLUS: "Plus",
  PREMIER: "Premier",
  ADMIN: "Admin",
  CLIENT: "Client",
  DELEGATED_USER: "Delegated User",
};

const NOTIFICATION_TYPES: iNotificationType[] = [
  {
    label: "Appointment Reminder",
    emailSubtext: "Receive an email a day before the appointment.",
    smsSubtext:
      "Receive a text message on your phone a day before the appointment.",
    emailProp: "aptReminderEmail",
    smsProp: "aptReminderSms",
    allowedRoles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
  },
  {
    label: "Appointment Cancellation",
    emailSubtext:
      "Receive an email if your appointment is cancelled by the office.",
    smsSubtext:
      "Receive a text message on your phone if your appointment is cancelled by the office.",
    emailProp: "aptCancellationEmail",
    smsProp: "aptCancellationSms",
    allowedRoles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
  },
  {
    label: "Appointment Opening",
    emailSubtext:
      "Receive an email when new appointment is available for the office you follow.",
    smsSubtext:
      "Receive a text message on your phone when new appointment is available for the office you follow.",
    emailProp: "aptOpeningEmail",
    smsProp: "aptOpeningSms",
    allowedRoles: [ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
  },
  {
    label: "Appointment Transfer",
    emailSubtext:
      "Receive an email on when you receive a transfer of an appointment from another premier user.",
    smsSubtext:
      "Receive a text message on your phone when you receive a transfer of an appointment from another premier user.",
    emailProp: "aptTransferEmail",
    smsProp: "aptTransferSms",
    allowedRoles: [ROLE_NAMES.PREMIER],
  },
  {
    label: "Direct Messages from Offices",
    smsSubtext:
      " Receive a text message on your phone when an office sends you a direct message via email.",
    smsProp: "ofcMessageSms",
    allowedRoles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
  },
];
const getDisplayDate = (date: string) => {
  return moment(date).format("M/D/YY");
};

const makeToast = (message: string, type: string, time: number = 3000) => {
  const t =
    type === "success" ? toast.success : type === "error" ? toast.error : toast;
  t(message, {
    duration: time,
    position: "top-center",
  });
};
const sortAppointments = (appointments: Appointment[]) => {
  return [...appointments].sort((a, b) => {
    const dateA = moment(a.date, "YYYY-MM-DD");
    const dateB = moment(b.date, "YYYY-MM-DD");

    return dateA.diff(dateB);
  });
};
const formatTime = (appointmentTimeInSeconds: number) => {
  const hours = Math.floor(appointmentTimeInSeconds / 3600) % 12;
  const minutes = Math.floor((appointmentTimeInSeconds % 3600) / 60);
  const isAM = Math.floor(appointmentTimeInSeconds / 3600) < 12;

  const formattedHours = hours === 0 ? 12 : hours;

  const formattedMinutes = String(minutes).padStart(2, "0");

  const ampm = isAM ? "AM" : "PM";

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
const HOME_SECTIONS = {
  benefits: "benefitsRef",
  howItWorks: "howItWorksRef",
  testimonial: "testimonialsRef",
  faq: "faqRef",
};
export {
  DATE_FORMAT,
  WEEK_DAY,
  getDisplayDate,
  TIME_FORMAT,
  makeToast,
  APPOINTMENT_DAY_FORMAT,
  HOME_SECTIONS,
  sortAppointments,
  formatTime,
  ROLE_NAMES,
  NOTIFICATION_TYPES,
  iNotificationType,
};
