import React from "react";
import "./register/Register.css";
import logo from "assets/images/logo/circular_logo.png";
import repLogo from "assets/images/auth/rep_register_logo.png";
import officeLogo from "assets/images/auth/client_register_logo.png";
import { FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import MainFooter from "./layout/MainFooter";
interface RegisterProps {}
interface RegistrationBoxProps {
  icon: string;
  title: string;
  subtext: string;
  invert?: boolean;
  onClick: () => void;
}

const RegistrationBox: React.FC<RegistrationBoxProps> = ({
  icon,
  title,
  subtext,
  invert,
  onClick,
}) => {
  return (
    <div
      className="registration-box-container"
      style={{
        backgroundColor: invert ? "#224984" : "#FFFFFF",
      }}
      onClick={onClick}
    >
      <img src={icon} alt="" className="registration-box-icon" />
      <div
        className="registration-box-title-container"
        style={{ color: invert ? "#fff" : "#000" }}
      >
        <p
          className="registration-box-title"
          style={{ color: invert ? "#fff" : "#000" }}
        >
          {title}
        </p>
        <FaArrowRight />
      </div>
      <p
        className="registration-box-subtext"
        style={{ color: invert ? "#fff" : "#000" }}
      >
        {subtext}
      </p>
    </div>
  );
};
const Register: React.FC<RegisterProps> = () => {
  const history = useHistory();

  return (
    <div className="register-main-container">
      <img src={logo} alt="" className="myrep-logo-top" />
      <div className="register-content-container">
        <p className="register-heading">Select your account type:</p>
        <div className="register-box-container">
          <RegistrationBox
            icon={repLogo}
            title="Representative"
            subtext="Pharmaceutical, diagnostic or medical sales, home health and hospice, MSLs and Nurse Educators"
            invert
            onClick={() => {
              history.push("/rep/basic");
            }}
          />
          <RegistrationBox
            icon={officeLogo}
            title="Medical Practice"
            subtext="Private Practice, Group Practice, Hospital-affiliated Practice"
            onClick={() => {
              history.push("/client");
            }}
          />
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default Register;
