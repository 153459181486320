import React from "react";
import "components/auth/onboarding.css";
import SectionTitle from "../SectionTitle";
import LoginInput from "components/auth/common/LoginInput";
import { iFromErrors, iLocationInfoForm } from "components/auth/common/types";

interface LocationInformationProps {
  handleChange: (prop: string) => (value: string) => void;
  data: iLocationInfoForm;
  formErrors: iFromErrors;
}

const cssPrefix = "onboarding-";
const LocationInformation: React.FC<LocationInformationProps> = ({
  data,
  handleChange,
  formErrors,
}) => {
  return (
    <div className={cssPrefix + "section-container"}>
      <SectionTitle label="Your Location" />
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="Address"
          value={data?.lineOne}
          name={"lineOne"}
          onChange={handleChange}
          type="text"
          placeholder="Enter your address"
          helperText={formErrors.lineOne}
        />
        <LoginInput
          label="Apt/Unit"
          value={data?.lineTwo}
          name={"lineTwo"}
          onChange={handleChange}
          type="text"
          placeholder="Enter Apt/Unit"
        />
      </div>
      <div className={cssPrefix + "horizontal-input-container"}>
        <LoginInput
          label="City"
          value={data?.city}
          name={"city"}
          onChange={handleChange}
          type="text"
          placeholder="Enter city"
          helperText={formErrors.city}
        />
        <LoginInput
          label="State"
          value={data?.state}
          name={"state"}
          onChange={handleChange}
          type="text"
          placeholder="Enter State"
          helperText={formErrors.state}
        />
      </div>
      <LoginInput
        label="zip"
        value={data?.zip}
        name={"zip"}
        onChange={handleChange}
        type="text"
        placeholder="Enter zip"
        helperText={formErrors.zip}
      />
    </div>
  );
};

export default LocationInformation;
