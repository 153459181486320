import React, { useState } from "react";
import "components/auth/ClientRegistration/clientRegistration.css";
import "components/auth/auth.css";
import { Modal } from "react-bootstrap";

import { iRegisterClientForm } from "../common/types";
import {
  initialRegisterClientForm,
  passwordRegex,
} from "../common/initialValues";
import { isValidEmail } from "components/common/utilities";
import LoginInput from "../common/LoginInput";
import SwitchAuth from "../common/SwitchAuth";
import AuthCheckboxInput from "../common/AuthCheckboxInput";
import { PRIVACY_LINK, TERMS_LINK } from "components/common/constants/links";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { registerClient } from "services/userServices";
import Loader from "components/common/Loader";

interface AccountCreationProps {}
const cssPrefix = "auth-page-";

const AccountCreation: React.FC<AccountCreationProps> = () => {
  const history = useHistory();

  const [formData, setFormData] = useState<iRegisterClientForm>(
    initialRegisterClientForm
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showTosModal, setShowTosModal] = useState<boolean>(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] =
    useState<boolean>(false);

  const handleChange = (prop: string) => (value: string) => {
    let validation: string | null = "";
    switch (prop) {
      case "email":
        if (!isValidEmail(value)) {
          validation = "Please enter a valid email address.";
        } else validation = null;
        break;
      case "password":
        if (!passwordRegex.test(value)) {
          validation =
            "Password must be at least 6 characters long and must contain at least 1 uppercase letter, 1 lowercase letter and 1 number.";
        } else {
          validation = null;
        }
        break;
      case "confirmPassword":
        if (formData?.password !== value) {
          validation = "Passwords do not match";
        } else validation = null;
        break;
      default:
        break;
    }
    setFormData((prev: iRegisterClientForm) => ({
      ...prev,
      [prop]: value,
      [`${prop}Validation`]: validation,
    }));
  };
  const checkForErrors = () => {
    let error = null;
    if (!formData.acceptedTerms)
      return "You must agree to the Terms of Service to proceed.";
    if (!formData.acceptedPolicy)
      return "You must agree to the Privacy Policy to proceed.";
    Object.keys(formData).forEach((key) => {
      if (
        key.includes("Validation") &&
        formData[key as keyof iRegisterClientForm] !== null
      ) {
        error = formData[key as keyof iRegisterClientForm];
      }
    });

    return error;
  };
  const handleSubmit = async () => {
    console.log({ formData });
    const error = checkForErrors();
    if (error) {
      toast.error(error, {
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: 5000,
      });
    } else {
      try {
        const payload = {
          Email: formData.email.trim(),
          Password: formData.password,
          ConfirmPassword: formData.confirmPassword,
        };
        setLoading(true);
        const res = await registerClient(payload);
        if (res.error) {
          throw new Error(res.error);
        }
        setLoading(false);
        history.replace("/check/email");
      } catch (e) {
        setLoading(false);
        const errorMessage =
          e instanceof Error ? e.message : "An unknown error occurred";

        swal("Error", errorMessage, "error");
      }
    }
  };
  const handleCheckboxChange = (prop: keyof iRegisterClientForm) => {
    setFormData((prev: iRegisterClientForm) => ({
      ...prev,
      [prop]: !prev?.[prop],
    }));
  };
  return loading ? (
    <Loader show />
  ) : (
    <div className={`${cssPrefix}container`}>
      <p className={`${cssPrefix}title`}>Create your account</p>

      <div className={cssPrefix + "content-container"}>
        <LoginInput
          label="Email Address"
          value={formData?.email}
          name={"email"}
          onChange={handleChange}
          type="email"
          helperText={formData.emailValidation}
          placeholder="Enter email address"
          onEnter={handleSubmit}
        />
        <LoginInput
          label="Password"
          value={formData?.password}
          name={"password"}
          onChange={handleChange}
          type="password"
          placeholder="Enter your password"
          onEnter={handleSubmit}
          helperText={formData.passwordValidation}
        />
        <LoginInput
          label="Confirm Password"
          value={formData?.confirmPassword}
          name={"confirmPassword"}
          onChange={handleChange}
          type="password"
          placeholder="Re-Enter your password"
          onEnter={handleSubmit}
          helperText={formData.confirmPasswordValidation}
        />
        <button className="login-form-btn" onClick={handleSubmit}>
          Email Verification
        </button>
      </div>
      <div className={cssPrefix + "tos-container"}>
        <AuthCheckboxInput
          handleCheckboxChange={() => handleCheckboxChange("acceptedTerms")}
          handleSpamClick={() => setShowTosModal(true)}
          checked={formData?.acceptedTerms}
          label="Agree to Terms"
        />
        <AuthCheckboxInput
          handleCheckboxChange={() => handleCheckboxChange("acceptedPolicy")}
          handleSpamClick={() => setShowPrivacyPolicyModal(true)}
          checked={formData?.acceptedPolicy}
          label="Agree to Privacy Policy"
        />
      </div>
      <SwitchAuth
        title="Already have an account?"
        link="/login"
        linkText="Log in"
      />

      <Modal
        show={showTosModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowTosModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <embed
            src={TERMS_LINK}
            style={{
              width: 700,
              height: 700,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="Button" onClick={() => setShowTosModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPrivacyPolicyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowPrivacyPolicyModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Privacy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <embed
            src={PRIVACY_LINK}
            style={{
              width: 700,
              height: 700,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="Button"
            onClick={() => setShowPrivacyPolicyModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AccountCreation;
