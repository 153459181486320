import React, { useState } from "react";
import NavHeader, { NavLink } from "./NavHeader";
import useLogout from "./useLogout";

import "../../sass/zeplin/ClinicHeader.scss";

import {
  AccountsIcon,
  CalendarIcon,
  ProfileIcon,
  BookedIcon,
  LogoutIcon,
  AnnouncementsIcon,
} from "./ClinicHeader";
import { ICONS } from "./constants/swal.constants";
import GenericModal from "./GenericModal";

const links = [
  { pathname: "/rep/accounts", Img: AccountsIcon, name: "Accounts" },
  { pathname: "/rep/appointments", Img: CalendarIcon, name: "Appointments" },
  { pathname: "/booked", Img: BookedIcon, name: "Booked" },
  { pathname: "/rep/settings", Img: ProfileIcon, name: "Profile" },
  // { pathname: "messages", Img: AnnouncementsIcon, name: "Announcements" }
];

const RepHeader = () => {
  const [genericModalData, setGenericModalData] = useState({
    show: false,
  });
  const showLogoutSuccess = () => {
    setGenericModalData({
      show: true,
      title: "Goodbye",
      subtext: "See you next time!",
      icon: ICONS.checkMark,
    });
  };
  const showLogoutError = () => {
    setGenericModalData({
      show: true,
      title: "Oops!",
      subtext: "Some error occurred",
      icon: ICONS.error,
    });
  };
  const hideModal = () => {
    setGenericModalData((prevState) => ({ ...prevState, show: false }));
  };

  const logOut = useLogout(
    "/login",
    showLogoutSuccess,
    showLogoutError,
    hideModal
  );
  return (
    <>
      {genericModalData.show && (
        <GenericModal
          show={genericModalData.show}
          onHide={hideModal}
          title={genericModalData?.title || ""}
          subtext={genericModalData?.subtext || ""}
          icon={genericModalData?.icon || undefined}
          hideBtn
        />
      )}
      <NavHeader
        links={links}
        renderLinks={() => {
          return (
            <NavLink
              Img={LogoutIcon}
              onClick={() => {
                return logOut();
              }}
              name="Log out"
            />
          );
        }}
      />
    </>
  );
};

export default RepHeader;
