import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "components/context/UserContext";
import { ClientDataContext } from "components/context/ClientDataContext";
import {
  isClinicProfileComplete,
  isProfileComplete,
  isProfileConfirmedByAdmin,
} from "../../common/profile";
import { getAllProfiles, getProfile } from "services/profileServices";
import { extractAppointmentRequirementsFromProfileData } from "components/common/utilities";

import Logo from "../../common/Logo";
import NotConfirmedMessage from "../../rep/accounts/NotConfirmedMessage";
import ClinicHeader from "../../common/ClinicHeader";
import ServerIssueMessage from "components/common/ServerIssueMessage";
import TokenErrorMessage from "components/common/TokenErrorMessage";
import LoadingOverlay from "components/common/LoadingOverlay";
import OnboardingFormAuth from "components/auth/onboarding/OnboardingForm";

const ClientDashboard = (props) => {
  const { state, dispatch } = useContext(UserContext);
  const { data, dispatchAction } = useContext(ClientDataContext);
  const location = useLocation();

  const [tokenError, setTokenError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loading = isLoading || data.isLoading;

  const isProfileApprovedByAdmin = isProfileConfirmedByAdmin(state.profile);
  const isProfileCompletedByUser = isProfileComplete(state.profile);

  const showOnboarding =
    !!state.profile.roleId &&
    !isClinicProfileComplete(state.profile) &&
    !["/login", "/register"].includes(location.pathname);
  const showNotConfirmed =
    isProfileCompletedByUser && !isProfileApprovedByAdmin;

  const showLoadingState = () => setIsLoading(true);
  const hideLoadingState = () => setIsLoading(false);

  const fetchProfileData = async () => {
    if (
      state.profile.id &&
      isProfileApprovedByAdmin &&
      isProfileCompletedByUser
    )
      return;
    try {
      setFetchError(false);
      setTokenError(false);
      showLoadingState();
      const res = await getProfile();

      if (res.code === 401) {
        setTokenError(true);
      }
      if (res.item) {
        dispatch({ type: "SET_PROFILE", payload: { profile: res.item } });
        dispatchAction({
          type: "SET_APPOINTMENT_REQUIREMENTS",
          payload: {
            appointmentRequirements:
              extractAppointmentRequirementsFromProfileData(res.item),
          },
        });
      }

      hideLoadingState();
    } catch (ex) {
      setFetchError(true);
      hideLoadingState();
    }
  };

  const fetchClientData = async () => {
    if (data.initialDataFetched) return;
    dispatchAction({ type: "FETCH_REQUEST" });
    const usersData = await getAllProfiles();
    dispatchAction({ type: "SET_REPS", payload: { reps: usersData.users } });
    dispatchAction({ type: "FETCH_COMPLETE" });
    dispatchAction({ type: "INITIAL_DATA_FETCHED" });
  };

  const loadData = async () => {
    await fetchProfileData();
    await fetchClientData();
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Logo />
        <LoadingOverlay />
      </div>
    );

  if (tokenError) return <TokenErrorMessage />;
  if (fetchError) return <ServerIssueMessage />;

  if (!loading && showOnboarding)
    // if (!loading)
    return (
      <>
        <OnboardingFormAuth isRep={false} onSubmit={() => fetchProfileData()} />
      </>
    );

  if (showNotConfirmed) return <NotConfirmedMessage />;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <div>
        <ClinicHeader />
        {props.children}
      </div>
    </div>
  );
};

export default ClientDashboard;
