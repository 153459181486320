import React from "react";
import { Link } from "react-router-dom";
import "components/auth/auth.css";
interface SwitchAuthProps {
  title: string;
  link: string;
  linkText: string;
}
const SwitchAuth: React.FC<SwitchAuthProps> = ({ title, link, linkText }) => {
  return (
    <div className="create-account-container">
      <span>{title}</span>{" "}
      <Link to={link} className="create-account-label">
        {linkText}
      </Link>
    </div>
  );
};

export default SwitchAuth;
