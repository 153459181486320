import React, { Component } from "react";
import * as validationHelper from "../../helpers/validation.helper";
import "../../css/join.css";
import Step1 from "../wizard/Step1";
import * as userServices from "../../services/userServices.js";
import Header from "../Header";
import MainFooter from "../layout/MainFooter";
import { Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "../payments/Checkout";
import { Modal } from "react-bootstrap";
import { makeToast } from "helpers/common";

import "../../css/join.css";
import "../../css/animate-3.7.0.css";
import "../../css/font-awesome-4.7.0.min.css";
import "../../css/bootstrap-4.1.3.min.css";
import "../../css/owl-carousel.min.css";
import "../../css/jquery.datetimepicker.min.css";
import "../../css/linearicons.css";
import "../../css/style.css";
import { PRIVACY_LINK, TERMS_LINK } from "components/common/constants/links";

// require("history").createBrowserHistory;

let { REACT_APP_STRIPE_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
const lsKey = "myrep-registration-step";

class RegisterPremierUser extends Component {
  constructor(props) {
    super(props);

    console.log(props);
    const formData = this.initValidation(props);
    this.state = {
      formData: formData,
      // currentStep: 1,
      formValid: false,
      products: ["input-0"],
      customerData: null,
      customerId: "",
      acctType: null,
      userId: "",
      termsModal: false,
      agree: false,
      privacyModal: false,
      privacy: false,
    };

    this.onChange = validationHelper.onChange.bind(this);

    // this.registerUser = this.registerUser.bind(this);
    this.confirmPasswordsMatch = this.confirmPasswordsMatch.bind(this);
    this.addProduct = this.addProduct.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const formData = this.initValidation(nextProps);
    this.setState({ formData: formData });
  }

  componentDidMount() {
    const customerData = this.getSavedCustomerData();
    this.setState({ currentPage: customerData });
  }

  addProduct() {
    const newProduct = `input-${this.state.products.length}`;
    this.setState((prevState) => ({
      products: prevState.products.concat([newProduct]),
    }));
  }

  getSavedCustomerData() {
    const localStorageItem = sessionStorage.getItem(lsKey); // sessionStorage doesn't persist after the tab or window closed
    if (typeof localStorageItem !== "undefined")
      return JSON.parse(localStorageItem);
    return null;
  }

  initValidation(props) {
    // const user = props.formData && props.formData._id ? props.formData : {};

    const initializedUser = {
      email: "",
      password: "",
      confirmPassword: "",
    };

    let formData = {
      password: {
        originalValue: initializedUser.password,
        value: initializedUser.password,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      email: {
        originalValue: initializedUser.email,
        value: initializedUser.email,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
      confirmPassword: {
        originalValue: initializedUser.confirmPassword,
        value: initializedUser.confirmPassword,
        valid: true,
        validation: {
          required: true,
        },
        touched: false,
      },
    };

    for (let fieldName in formData) {
      const field = formData[fieldName];
      field.valid = validationHelper.validate(field.value, field.validation);
    }

    return formData;
  }

  confirmPasswordsMatch(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState((prevState) => {
      const field = { ...prevState.formData[name] };
      field.value = value;
      field.touched = true;
      field.valid = this.state.formData.password.value === value;
      const formData = { ...prevState.formData, [name]: field };
      let formValid = true;
      for (let inputIdentifier in formData) {
        formValid = formValid && formData[inputIdentifier].valid;
      }
      return { formData: formData, formValid: formValid };
    });
  }

  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn--outline btn--white float-left"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  // nextButton() {
  //   let currentStep = this.state.currentStep;
  //   if (currentStep === 1) {
  //     return (
  //       <button
  //         className="btn btn--outline btn--white float-right"
  //         type="button"
  //         onClick={this.createCustomer}
  //       >
  //         Submit
  //       </button>
  //     );
  //   }
  //   return null;
  // }

  // Registers as Role 3 after Card is accepted it updates to Role 5
  submitRegister = (e, data) => {
    if (this.state.agree === true && this.state.privacy === true) {
      let payload = {
        Email: this.state.formData.email.value,
        Password: this.state.formData.password.value,
        ConfirmPassword: this.state.formData.confirmPassword.value,
      };
      userServices
        .registerRep(payload)
        .then((result) => {
          if (result.error) throw new Error(result.error);

          return this.createCustomer(result);
        })
        .catch(this.onErrorResponse);
    } else {
      makeToast("Must Agree to Terms & Privacy", "error");
    }
  };

  createCustomer = (e) => {
    console.log(e, "CreateCustomer");
    let data = {
      email: this.state.formData.email.value,
    };
    this.setState({
      userId: e.item,
    });

    return (
      userServices
        .getCreateCustomer(data)
        // .then((response) => {
        //   console.log("stripe resposne:: " + JSON.stringify(response));
        //   this.setState({ customerId: response.item.stripeId });
        //   // return response.json();
        // })
        .then((result) => {
          // this.submitRegister();
          if (result.error) {
            throw new Error(result.error);
          }
          this.setState({ customerData: result });
          sessionStorage.setItem(lsKey, JSON.stringify(result)); // TODO maybe store this info on the server, or allow for expiring
          return result;
        })
    );
  };

  handlePayment = (cardElement, customerId, priceId) => { };

  onSuccessResponse = (response) => {
    makeToast("Continue To Payment", 'success');
  };

  onErrorResponse = (error) => {
    if (error?.message) {
      makeToast(error.message, 'error');
    } else {
      makeToast("Something went wrong with your request", "error");
    }
    // console.log(error.response.data, "data");
    // console.log(error.response.status, "status");
    // let status = error.response.status;
    // if (status === 400) {
    //   swal("Error", "Please Check Password Premium", "error");
    // } else if (status === 500) {
    //   let err = error.response.data.errors[0];
    //   swal("Error", err, "error");
    // } else {
    //   console.log(error.response.headers);
    //   let err = error.response.data.errors[0];
    //   swal("Error", err, "error");
    // }
  };

  handleModal = () => {
    this.setState({
      termsModal: true,
    });
  };

  handlePrivacyModal = () => {
    this.setState({
      privacyModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      termsModal: false,
    });
  };

  handleClosePrivacyModal = () => {
    this.setState({
      privacyModal: false,
    });
  };

  agreeState = () => {
    this.setState((prevState) => ({
      agree: !prevState.agree,
    }));
  };

  agreePrivacy = () => {
    this.setState((prevState) => ({
      privacy: !prevState.privacy,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        {/* TERMS MODAL */}
        <Modal
          show={this.state.termsModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src={TERMS_LINK}
              width="750px"
              height="2100px"
            />
            {/* <iframe src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf" style="width:600px; height:500px;" frameborder="0"></iframe> */}
          </Modal.Body>
          <Modal.Footer>
            <btn
              className="btn-primary btn-pill"
              onClick={this.handleCloseModal}
            >
              Close
            </btn>
          </Modal.Footer>
        </Modal>

        {/* Privacy Modal */}
        <Modal
          show={this.state.privacyModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src={PRIVACY_LINK}
              width="750px"
              height="2100px"
            />
            {/* <iframe src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf" style="width:600px; height:500px;" frameborder="0"></iframe> */}
          </Modal.Body>
          <Modal.Footer>
            <btn
              className="btn-primary btn-pill"
              onClick={this.handleClosePrivacyModal}
            >
              Close
            </btn>
          </Modal.Footer>
        </Modal>
        <div class="page page--join mt-2">
          <div
            class="container-fluid d-flex justify-content-left step1_wizard"
            style={{ paddingLeft: 0 }}
          >
            {" "}
            <div className="mt-2" style={{ margin: "0 auto" }}>
              <div className="container">
                <div
                  className="row"
                  style={{
                    marginTop: 225,
                    marginBottom: "3rem",
                    width: "100%",
                  }}
                >
                  {!this.state.customerData && (
                    <div class="col-xs-6 formspace">
                      <Step1
                        // currentStep={this.state.currentStep}
                        handleChange={this.onChange}
                        formData={this.state.formData}
                        onRegister={this.submitRegister}
                        referralId={this.props.location.state}
                        handleTermsModal={this.handleModal}
                        handlePrivacyModal={this.handlePrivacyModal}
                        agreeState={this.agreeState}
                        agreePrivacy={this.agreePrivacy}
                      />
                    </div>
                  )}
                  <div class="col-xs-6 inline-form formspace checkout_form">
                    {this.state.customerData && (
                      <Elements stripe={stripePromise}>
                        <Checkout
                          {...this.props}
                          customerId={this.state.customerId}
                          onRegister={this.submitRegister}
                          formData={this.state.formData}
                          id={this.state.userId}
                          referralId={this.props.location.state}
                        />
                      </Elements>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row"></div> */}
        </div>
        <MainFooter />
      </React.Fragment>
    );
  }
}

export default RegisterPremierUser;
