import React, { useEffect, useReducer, useState } from "react";
import "./auth.css";
import LoginInput from "./common/LoginInput";
import { iRecoverForm } from "./common/types";
import { initialLoginForm } from "./common/initialValues";
import { isValidEmail } from "components/common/utilities";
import * as passwordService from "services/emailService";
import Logo from "assets/images/logo/circular_logo.png";
import { GenericModalProps } from "./ResetPassword";
import GenericModal from "components/common/GenericModal";
import { ICONS } from "components/common/constants/swal.constants";

interface RecoverPasswordProps {}

interface PasswordState {
  email: string;
}

type Action = { type: "SET_EMAIL"; payload: string } | { type: "RESET_FORM" };

const passwordReducer = (
  state: PasswordState,
  action: Action
): PasswordState => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "RESET_FORM":
      return { email: "" };
    default:
      return state;
  }
};
const RecoverPassword: React.FC<RecoverPasswordProps> = () => {
  const [formData, setFormData] = useState<iRecoverForm>(initialLoginForm);
  const [, dispatch] = useReducer(passwordReducer, { email: "" });
  const [genericModalData, setGenericModalData] = useState<GenericModalProps>({
    show: false,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (prop: string) => (value: string) => {
    let validation: string | null = "";
    switch (prop) {
      case "email":
        if (!isValidEmail(value)) {
          validation = "Please enter a valid email address.";
        } else validation = null;
        break;
      default:
        break;
    }
    setFormData((prev: iRecoverForm) => ({
      ...prev,
      [prop]: value,
      [`${prop}Validation`]: validation,
    }));
  };

  const onSuccess = () => {
    setLoading(false);

    setGenericModalData({
      show: true,
      title: "Email Sent",
      subtext: "Please follow the link in the email to reset your password.",
      icon: ICONS.checkMark,
    });
  };

  const onFailure = (error: Error) => {
    setLoading(false);

    setGenericModalData({
      show: true,
      title: "An error occurred!",
      subtext: error.message,
      icon: ICONS.error,
    });
  };
  const handleSubmit = () => {
    setLoading(true);
    passwordService
      .forgotPass(formData.email)
      .then((res) => {
        if (res.success) return onSuccess();

        const error = res.error || "Error";
        throw new Error(error);
      })
      .catch(onFailure);
  };
  const handleGenericModalToggle = (toggle: boolean) => {
    setGenericModalData((prev: GenericModalProps) => ({
      ...prev,
      show: toggle,
    }));
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch({ type: "RESET_FORM" });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="login-container">
      {genericModalData.show && (
        <GenericModal
          show={genericModalData.show}
          onHide={() => handleGenericModalToggle(false)}
          title={genericModalData?.title || ""}
          subtext={genericModalData?.subtext || ""}
          btnText="OK"
          onBtnClick={() => handleGenericModalToggle(false)}
          icon={genericModalData?.icon || undefined}
        />
      )}
      <div className="login-content-container">
        <img src={Logo} alt="" className="l-logo" />

        <p className="login-form-title">Recover your password</p>
        <div className="login-form-container">
          <LoginInput
            label="Please verify your registered email"
            value={formData?.email}
            name={"email"}
            onChange={handleChange}
            type="email"
            helperText={formData.emailValidation}
            placeholder="Enter registered email"
          />

          <button
            className="login-form-btn"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Verifying..." : "Verify"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
