import React, { useRef } from "react";
import BannerSection from "./homeRedesign/BannerSection";
import ScheduleSection from "./homeRedesign/ScheduleSection";
import BenefitsSection from "./homeRedesign/BenefitsSection";
import HowItWorksSection from "./homeRedesign/HowItWorksSection";
import TestimonialsSections from "./homeRedesign/TestimonialsSections";
import FaqSection from "./homeRedesign/FaqSection";
import ContactSection from "./homeRedesign/ContactSection";
import Header from "./Header";
import { HOME_SECTIONS } from "helpers/common";
interface HomeRedesignProps {}
const HomeRedesign: React.FC<HomeRedesignProps> = () => {
  const benefitsRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);

  //Test for deployment
  const scrollToSection = (sectionName: string) => {
    switch (sectionName) {
      case HOME_SECTIONS.benefits:
        if (benefitsRef.current) {
          benefitsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case HOME_SECTIONS.howItWorks:
        if (howItWorksRef.current) {
          howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case HOME_SECTIONS.testimonial:
        if (testimonialsRef.current) {
          testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case HOME_SECTIONS.faq:
        if (faqRef.current) {
          faqRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="home-container">
      <Header scrollToSection={scrollToSection} />
      <BannerSection />
      <ScheduleSection />
      <div ref={benefitsRef}>
        <BenefitsSection />
      </div>
      <div ref={howItWorksRef}>
        <HowItWorksSection />
      </div>
      <div ref={testimonialsRef}>
        <TestimonialsSections />
      </div>
      <div ref={faqRef}>
        <FaqSection />
      </div>
      <ContactSection />
    </div>
  );
};

export default HomeRedesign;
