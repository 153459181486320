import React, { useContext, useEffect, useState } from "react";
import "./auth.css";
import LoginInput from "./common/LoginInput";
import { iLoginForm, iResponseData } from "./common/types";
import {
  initialLoginForm,
  loginFormValidationRules,
} from "./common/initialValues";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "components/context/UserContext";
import { isValidEmail, roleBasedRedirect } from "components/common/utilities";
import * as validationHelper from "helpers/validation.helper";
import * as userService from "services/userServices";
import { setAuthToken } from "components/common/authTokenManager";
import swal from "sweetalert";
import SwitchAuth from "./common/SwitchAuth";
import Logo from "assets/images/logo/circular_logo.png";

interface LoginProps {}

interface LocationState {
  redirectTo?: string;
}
const Login: React.FC<LoginProps> = () => {
  const [formData, setFormData] = useState<iLoginForm>(initialLoginForm);
  const [loginError, setLoginError] = useState<string | null>(null);

  const { state, dispatch } = useContext(UserContext)!;
  const { currentUser } = state;

  const location = useLocation<LocationState>();
  const history = useHistory();

  const handleChange = (prop: string) => (value: string) => {
    let validation: string | null = "";
    switch (prop) {
      case "email":
        if (!isValidEmail(value)) {
          validation = "Please enter a valid email address.";
        } else validation = null;
        break;
      default:
        break;
    }
    setFormData((prev: iLoginForm) => ({
      ...prev,
      [prop]: value,
      [`${prop}Validation`]: validation,
    }));
  };

  const handleValidation = (data: iLoginForm) => {
    setLoginError(null);
    const errors: string[] = [];

    Object.keys(data).forEach((field) => {
      if (
        !validationHelper.validate(
          data?.[field as keyof iLoginForm],
          loginFormValidationRules?.[
            field as keyof typeof loginFormValidationRules
          ]
        )
      ) {
        errors.push(`The ${field} field is required`);
      }
    });

    if (errors.length > 0) {
      setLoginError(errors.join(". "));
      return false;
    }
    return true;
  };

  const onCurrentUserSuccess = async (data: iResponseData) => {
    try {
      const config = (await userService.getConfig()) || [];
      const releaseDates = (await userService.getReleaseDates()) || [];
      dispatch({ type: "UPDATE_CONFIG", payload: { config } });
      dispatch({ type: "UPDATE_RELEASE_DATES", payload: { releaseDates } });

      const ff = (await userService.getFeatureFlags()) || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let activeFeatures = ff.filter((f: any) => f.value === 1);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      activeFeatures = activeFeatures.map((el: any) => el.name);

      if (activeFeatures.length > 0) {
        dispatch({
          type: "SET_ACTIVE_FEATURES",
          payload: { features: activeFeatures },
        });
      }

      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...data.item, isLoggedIn: true } },
      });

      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log({ e });

      // toast(e.message?.toString(), {
      //   autoClose: 2000,
      //   type: toast.TYPE.ERROR,
      //   hideProgressBar: true,
      //   position: toast.POSITION.TOP_CENTER,
      //   pauseOnHover: false,
      // });
      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...data.item, isLoggedIn: true } },
      });
      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
    }
  };

  const handleLogin = async (data: iLoginForm) => {
    const item = {
      email: data.email,
      password: data.password,
    };
    dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: true } });
    userService
      .login(item)
      .then((res) => {
        if (res.item && res.item.token) {
          setAuthToken(res.item.token);
        }
        if (res.error) throw new Error(res.error);
        if (res.forceLogout) {
          dispatch({
            type: "SET_LOADING_STATE",
            payload: { isLoading: false },
          });

          return swal(
            "Only one device allowed per account at a time",
            "You have been logged out of your other devices for security reasons."
          ).then(() => {
            dispatch({
              type: "SET_LOADING_STATE",
              payload: { isLoading: true },
            });

            return userService.getCurrent();
          });
        } else {
          return userService.getCurrent();
        }
      })
      .then(onCurrentUserSuccess)
      .catch((err) => {
        dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: false } });
        return setLoginError(err.message);
      });
  };

  const handleSubmit = async () => {
    // console.log({ formData });

    const isValid = handleValidation(formData);
    if (!isValid) return;

    handleLogin(formData);
  };

  useEffect(() => {
    if (currentUser && currentUser.roleName) {
      if (location.state && location.state.redirectTo) {
        return history.replace(location.state.redirectTo);
      }

      roleBasedRedirect(currentUser.roleName, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="login-container">
      <div className="login-content-container">
        <img src={Logo} alt="" className="l-logo" />
        <p className="login-form-title">Log in to your account</p>
        <div className="login-form-container">
          <LoginInput
            label="Email Address"
            value={formData?.email}
            name={"email"}
            onChange={handleChange}
            type="email"
            helperText={formData.emailValidation}
            placeholder="Enter email address"
            onEnter={handleSubmit}
          />
          <LoginInput
            label="Password"
            value={formData?.password}
            name={"password"}
            onChange={handleChange}
            type="password"
            placeholder="Enter your password"
            onEnter={handleSubmit}
          />
          <Link to="/password" className="forgot-password-label">
            Forgot Password?
          </Link>

          <button className="login-form-btn" onClick={handleSubmit}>
            Login
          </button>
        </div>
        <SwitchAuth
          title="Don’t have an account?"
          link="/register"
          linkText=" Create Account"
        />

        {loginError && (
          <div
            style={{
              marginBottom: "1rem",
              backgroundColor: "salmon",
              padding: "10px",
              borderRadius: 3,
              color: "rgba(255,255,255,0.95)",
            }}
          >
            {loginError}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
