import { ROLE_NAMES } from "./helpers/common";
import { lazy } from "react";

const allRoutes = [
  {
    path: "/admin/invite",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/admin/invite/Invite.jsx")),
  },
  {
    path: "/admin/approvals",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/admin/approvals/Approvals.tsx")),
  },
  {
    path: "/rep/settings/upgrade",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS],
    component: lazy(() => import("./components/rep/settings/Upgrade.jsx")),
  },
  {
    path: "/rep/settings/subscriptions/plans",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() =>
      import("./components/rep/settings/subscriptions/SubscriptionPlans.tsx")
    ),
  },
  {
    path: "/register/company",
    exact: true,
    roles: [
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
    ],
    component: lazy(() => import("./components/register/RegisterCompany")),
  },
  {
    path: "/register/location",
    exact: true,
    roles: [
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
    ],
    component: lazy(() => import("./components/register/RegisterLocation")),
  },
  {
    path: "/register/products",
    exact: true,
    roles: [
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
    ],
    component: lazy(() => import("./components/register/RegisterProducts")),
  },
  {
    path: "/register/profile",
    exact: true,
    roles: [
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
    ],
    component: lazy(() => import("./components/register/RegisterProfile.jsx")),
  },
  {
    path: "/client/user-activity",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/DelegatedUserActivity.tsx")
    ),
  },
  {
    path: "/client/calendar",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/calendar/Calendar.tsx")
    ),
  },
  {
    path: "/client/reps",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/Reps/FollowingReps")
    ),
  },
  // {
  //   path: "/client/reps",
  //   exact: true,
  //   roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
  //   component: lazy(() => import("./components/client/dashboard/Reps/Reps")),
  // },
  {
    path: "/client/settings",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/settings/Dieterary.jsx")
    ),
  },
  {
    path: "/client/restrictions",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/restrictions/Restrictions.jsx")
    ),
  },
  {
    path: "/rep/welcome",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/WelcomeRepUser.jsx")),
  },
  {
    path: "/rep/calendar",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/client/dashboard/Calendar")),
  },
  {
    path: "/rep/accounts",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/accounts/Accounts.jsx")),
  },
  {
    path: "/rep/onboarding",
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/common/OnboardingForm.js")),
  },
  {
    path: "/rep/appointments",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() =>
      import("./components/rep/appointments/AppointmentsRep.tsx")
    ),
  },
  {
    path: "/welcome",
    exact: true,
    roles: [
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
    ],
    component: lazy(() => import("./components/client/dashboard/Landing.jsx")),
  },
  {
    path: "/booked",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/booked/Booked.jsx")),
  },
  {
    path: "/booked/details/:apptId",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/booked/BookedDetails.jsx")),
  },
  {
    path: "/referral",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/referral/Referral.jsx")),
  },
  {
    path: "/appointments",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/appointments/Appointments.tsx")
    ),
  },
  {
    path: "/appointments/:id",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import(
        "./components/client/dashboard/appointments/AppointmentDetails.jsx"
      )
    ),
  },
  {
    path: "/stats",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/admin/stats/Blocks.jsx")),
  },
  {
    path: "/assets",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/admin/assets/Assets.jsx")),
  },
  {
    path: "/users",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() =>
      import("./components/admin/users/UserProfileLite.jsx")
    ),
  },
  {
    path: "/referals",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/admin/referals/Referal.jsx")),
  },
  {
    path: "/announcement",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() =>
      import("./components/admin/announcement/Announcement.jsx")
    ),
  },
  {
    path: "/profile",
    exact: true,
    roles: [ROLE_NAMES.CLIENT, ROLE_NAMES.DELEGATED_USER],
    component: lazy(() =>
      import("./components/client/dashboard/profile/OfficeProfile.tsx")
    ),
  },
  {
    path: "/rep/settings",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() =>
      import("./components/rep/settings/UserProfileLiteRep.jsx")
    ),
  },

  {
    path: "/rep/settings/update-card",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/settings/UpdateCard.jsx")),
  },
  {
    path: "/rep/settings/Invoices",
    exact: true,
    roles: [ROLE_NAMES.BASIC, ROLE_NAMES.PLUS, ROLE_NAMES.PREMIER],
    component: lazy(() => import("./components/rep/settings/Invoices.jsx")),
  },
  {
    path: "/notifications/list",
    exact: true,
    roles: [
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PREMIER,
      ROLE_NAMES.CLIENT,
      ROLE_NAMES.DELEGATED_USER,
    ],
    component: lazy(() => import("./components/common/NotificationsList.js")),
  },
  {
    path: "/messages",
    exact: true,
    roles: [
      ROLE_NAMES.BASIC,
      ROLE_NAMES.PLUS,
      ROLE_NAMES.PREMIER,
      ROLE_NAMES.CLIENT,
    ],
    component: lazy(() => import("./components/chats/Chats")),
  },
  {
    path: "/admin/messages/rep",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() => import("./components/rep/messages/Messages.jsx")),
  },
  {
    path: "/admin/messages/office",
    exact: true,
    roles: [ROLE_NAMES.ADMIN],
    component: lazy(() =>
      import("./components/client/dashboard/messages/Messages.jsx")
    ),
  },
];

export default allRoutes;
